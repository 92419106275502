<template>
<div class="home">
    <div class="home-top">
        <SearchTop />
        <Swiper />
    </div>
    <div class="class-nav-container">
        <div class="class-nav-outer" @scroll="scorllEvt">
            <div class="class-nav">
                <router-link to="/productList/6">
                    <div class="logo-wrap">
                        <img src="../assets/ac_navIcon05.png" alt="">
                    </div>
                    <div class="class-navtext">
                        文化素养
                    </div>
                </router-link>
                 <router-link to="/productList/2">
                    <div class="logo-wrap">
                        <img style="height:50%" src="../assets/ac_navIcon01.png" alt="">
                    </div>
                    <div class="class-navtext">
                        非遗文化
                    </div>
                </router-link>
                <router-link to="/productList/4">
                    <div class="logo-wrap">
                        <img src="../assets/ac_navIcon03.png" alt="">
                    </div>
                    <div class="class-navtext">
                        科学探究
                    </div>
                </router-link>
                <router-link to="/productList/5">
                    <span class="specil-tag">新</span>
                    <div class="logo-wrap">
                        <img src="../assets/ac_navIcon04.png" alt="">
                    </div>
                    <div class="class-navtext">
                        劳动教育
                    </div>
                </router-link>
                <router-link to="/productList/3">
                    <span class="specil-tag">必看</span>
                    <div class="logo-wrap">
                        <img src="../assets/ac_navIcon02.png" alt="">
                    </div>
                    <div class="class-navtext">
                        行动教育
                    </div>
                </router-link>
                <router-link to="/productList/1">
                    <span class="specil-tag">热</span>
                    <div class="logo-wrap">
                        <img style="height:50%" src="../assets/ac_navIcon00.png" alt="">
                    </div>
                    <div class="class-navtext">
                        传统节日
                    </div>
                </router-link>                                                                               
            </div>
        </div>
        <div class="class-nav-dot-wrap">
            <div class="class-nav-dot" :style="'width:'+dotWidth+'px;transform:translateX('+ dotLeftX+'px)'"></div>
        </div>
    </div>

    <!-- <NoticeLine /> -->

    <div class="actives-content-wrap">
        <div class="class-tit">
            <div class="left-tit">
                <p>研学打卡</p>
                <p>游戏于乐，乐学无限</p>
                <span class="specil-tag">游乐学</span>
            </div>
            <router-link to="/productList/1" class="check-more">查看更多</router-link>
        </div>

        <div class="actives-outer">
            <div class="actives-wrap">
                <div class="actives-item" v-for="(item,index) in bannerList" :key="index">
                    <router-link :to="'/productDetails/'+item.Product_ID">
                        <div class="actives-img-wrap">
                            <img :src="domin + (item.Product_ImageBanner||item.Product_ImageUrl)" alt="active_imgs">
                        </div>
                        <h6>{{item.Product_Name}}</h6>
                    </router-link>
                    <span class="actives-statu">{{computeStatu(item.Product_FlashStart,item.Product_FlashEnd)}}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="actives-content-wrap">
        <div class="class-tit">
            <div class="left-tit">
                <p>研学打卡</p>
                <p>副標題文案</p>
                <span class="specil-tag study-specil-tag">小類標文案</span>
            </div>
            <router-link to="" class="check-more">查看更多</router-link>
        </div>

        <div class="actives-outer">
            <div class="actives-wrap">
                <div class="actives-item" v-for="i in 6" :key="i">
                    <div class="actives-img-wrap">
                        <img src="../assets/studyActive.jpg" alt="active_imgs">
                    </div>
                    <h6>研学打卡标题0{{i}}</h6>
                </div>
            </div>
        </div>
    </div> -->

    <div class="class-stu">
        <img class="stu-banner" :src="domin+imgActiveAd" alt="banner">
    </div>

    <div class="actives-content-wrap" style="background:none">
        <div class="class-tit">
            <div class="left-tit">
                <p>热门打卡</p>
                <p>有你有我有Ta</p>
                <span class="specil-tag act-specil-tag">快来吧</span>
            </div>
            <router-link to="/productList/1" class="check-more">必打卡活动</router-link>
        </div>

        <div class="actives-outer">
            <div class="actives-wrap last-actives-wrap">
                <div class="actives-item last-actives-item" v-for="(item,index) in productList" :key="index">
                    <router-link :to="'/productDetails/'+item.Product_ID">
                        <div class="actives-img-wrap">
                            <img :src="domin + item.Product_ImageUrl" alt="active_imgs">
                        </div>
                        <h6>{{item.Product_Name}}</h6>
                    </router-link>
                    <span class="actives-statu" :class="{'on':computeStatu(item.Product_FlashStart,item.Product_FlashEnd)=='进行中'}">{{computeStatu(item.Product_FlashStart,item.Product_FlashEnd)}}</span>

                </div>
                <div class="actives-item last-actives-item">
                    <div class="actives-words" style="height:calc(30vw + 26px)">
                        <router-link to="">排行前三</router-link>
                        <router-link to="">限时特惠</router-link>
                        <router-link to="">亲子玩乐</router-link>
                        <router-link to="">益智解谜</router-link>
                        <router-link to="">查看更多</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <NavBottom :checkNum="3" />
</div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            domin: this.domin,
            dotLeftX: 0,
            dotWidth: 20,
            bannerList: [],
            pageindex: 1,
            tabNameIndex: 1,
            productList: [],
            imgActiveAd:""
        };
    },
    created() {
        this.getBannerList();
        this.getProuductList();
    },
    mounted(){
        this.getAdImg()
    },
    methods: {
        getAdImg(){
            this.wpost({
                url: "/Ajax/Member/getbannerlist",
                data: {
                    bannertype: 1
                },
                succ: res => {
                    console.log("ad img:", res.list);
                    this.imgActiveAd = res.list[0].Banner_Image;
                }
            });
        },
        computeStatu(start,end){
            let statuText= "";
            let starts = start.replace(/-/g,"/")
            let ends = end.replace(/-/g,"/")
            let stime = new Date(starts).getTime()
            let etime = new Date(ends).getTime()
            let now = Date.now()

            if(now < stime){
                statuText = "未开始";
            }
            else if(now > stime && now < etime){
                statuText = "进行中";

            }else if(now > etime){
                statuText = "已结束";

            }else{
                statuText = "未知";
            }


            return statuText
        },
        setTabNameIndex(num) {
            this.tabNameIndex = num;
        },
        scorllEvt(e) {
            // console.log("evt",e)
            // console.log("scroll evt",e.target.scrollLeft)
            // console.log("scroll offset",e.target.offsetWidth)
            // console.log("nav width",e.target.scrollWidth)
            this.dotLeftX = Math.floor(
                (10 * e.target.scrollLeft) /
                (e.target.scrollWidth - e.target.offsetWidth)
            );
        },
        
        getBannerList() {
            this.wpost({
                url: "/Ajax/WordWelfare/getproductlist",
                data: {
                    commend: 3,
                    pageindex: 1
                },
                succ: res => {
                    console.log("banner list:", res.list);
                    this.bannerList = res.list;
                }
            });
        },
        getProuductList() {
            this.wpost({
                url: "/Ajax/WordWelfare/getproductlist",
                data: {
                    commend: 4,
                    pageindex: this.pageindex
                },
                succ: res => {
                    console.log("banner list:", res.list);
                    this.productList = res.list;
                }
            });
        }
    }
};
</script>

<style scoped>
.home {
    text-align: center;
}

.actives-words {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.actives-words a {
    margin: 0 3px;
    display: block;
    font-size: 13px;
    color: #fff;
    padding: 3px 8px;
    height: 20px;
    border-radius: 10px;
    background-image: linear-gradient(to right, #4692f1, #ffc397);
}

.actives-content-wrap {
    margin: 12px 0;
    padding: 12px 0;
    background-color: #fff;
}

.actives-outer {
    overflow: hidden;
    overflow-x: scroll;
}

.actives-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.actives-item {
    flex: none;
    width: 60vw;
    margin: 6px;
    padding: 4px;
    box-shadow: 0 0 6px #ccc;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}


.last-actives-wrap {
    flex-wrap: wrap;
}

.last-actives-item {
    box-sizing: border-box;
    width: calc(50vw - 12px);
}

.last-actives-wrap .last-actives-item:first-child {
    width: calc(100% - 12px);
}

.last-actives-wrap .last-actives-item:first-child .actives-img-wrap {
    height: 36vw;
}

.actives-item h6 {
    margin: 0;
    padding: 5px 0;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actives-img-wrap {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    height: 30vw;
}

.actives-img-wrap img {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    width: 100%;
}

.specil-tag {
    right: 0;
    background-color: #ffc397;
}

.specil-tag::after {
    background-color: #ffc397;
}

.home-top {
    background-color: #f5f5f5;
    background-image: url("../assets/top_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    padding: 5px;
}

/* 大于五个导航样式 */
.class-nav-container {
    position: relative;
}

.class-nav-outer {
    width: calc(100% - 10px);
    overflow: hidden;
    overflow-x: auto;
    background-color: #fff;
    border-radius: 6px;
    padding: 10px 0;
    padding-bottom: 22px;
    margin: 0 5px;
    /* scrollbar: none;
  scrollbar-color:transparent transparent; */
}

.class-nav-dot-wrap {
    bottom: 10px;
    left: 50%;
    width: 30px;
    transform: translateX(-50%);
    position: absolute;
    background-color: #ccc;
    height: 6px;
    border-radius: 3px;
}

.class-nav-dot {
    transition: transform 100ms ease;
    background-color: #4790f2;
    height: 6px;
    position: absolute;
    left: 0;
    transform: translateX(0);
    border-radius: 3px;
    width: 20px;
}

.class-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.class-nav a {
    display: block;
    width: 20%;
    flex: none;
    position: relative;
    text-decoration: none;
}

.logo-wrap {
    height: 40px;
    width: 40px;
    margin: 3px auto;
    text-align: center;
    border-radius: 50%;
    background-image: linear-gradient(to right, #606cfc, #4692f1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-wrap img {
    height: 60%;
    vertical-align: middle;
}

.class-navtext {
    font-size: 13px;
    font-weight: bold;
    color: #333;
}

/* 两个导航样式 */

.class-stu {
    margin: 10px 5px;
    border-radius: 8px;
    background-color: #fff;
    font-size: 0;
}

.stu-banner {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.tabs-name {
    display: flex;
    padding: 5px 0;
}

.tab-item {
    width: 20%;
    font-size: 13px;
    color: #333;
    font-weight: bold;
    position: relative;
    text-decoration: none;
}

.tab-item::after {
    transition: width 300ms ease, border-color 400ms ease;
    transform-origin: center center;
    content: "";
    position: absolute;
    width: 20%;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    bottom: -3px;
    border-bottom: 2px solid transparent;
}

.tab-item.on::after {
    width: 55px;
    border-bottom-color: #fa583f;
}

.class-tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.left-tit {
    position: relative;
}

.left-tit .specil-tag {
    display: inline-block;
    border-radius: 4px;
    width: auto;
    padding: 0 5px;
    transform: translateX(calc(100% + 5px));
    background-image: linear-gradient(to right, #4692f1, #6466fe);
}

.left-tit .specil-tag::after {
    background-color: #4692f1;
    transform: skewX(138deg) rotate(100deg);
    bottom: 1px;
    left: -1px;
}

.left-tit .study-specil-tag {
    background-image: linear-gradient(to right, #4692f1, #ffc397);
}

.left-tit .act-specil-tag {
    background-image: linear-gradient(to right, #4692f1, #fa583f);
}

.left-tit p {
    text-align: left;
}

.left-tit p:first-child {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.left-tit p:nth-child(2) {
    font-size: 10px;
    color: #999;
}

.class-tit a {
    font-size: 11px;
    color: #999;
}

.teach-container {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
}

.teach-show {
    display: flex;
}

.teach-show::after {
    content: "";
    display: block;
    clear: both;
}

.teach-show>div {
    width: 36%;
    margin: 5px 1%;
}

.teach-img-wrp img {
    width: 100%;
    object-fit: contain;
}

.teach-img-tit {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
